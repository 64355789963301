import React from 'react'
import { graphql } from 'gatsby'
import Navbar from '../../../sections/Navbar/Navbar'
import Footer from '../../../sections/Footer'
import { PrismicLink, PrismicRichText } from "@prismicio/react";
import "../../../styles/AdminPage.scss";

function HRAdminDashboard({ data }: any) {
    const info = data.prismicHrAdminDashboard.dataRaw
    const infoImg = data.prismicHrAdminDashboard.data
    return (
        <div>
            <Navbar />
            <div className='AdminPage'>
                <div className='AdminPage-title'>
                    <PrismicRichText field={info.title} />
                </div>
                <div className='AdminPage-second-title'>
                    <PrismicRichText field={info.updated} />
                </div>
                <div className='AdminPage-title'>
                    <PrismicRichText field={info.title1} />
                </div>
                <div className='AdminPage-second-title'>
                    <PrismicRichText field={info.texte} />
                    <img className="AdminPage-image" src={infoImg.im1.url} alt={infoImg.im1.alt} />
                </div>
                <div className='AdminPage-second-title'>
                    <PrismicRichText field={info.texte1} />
                    <img className="AdminPage-image" src={infoImg.im2.url} alt={infoImg.im2.alt} />
                </div>
                <div className='AdminPage-second-title'>
                    <PrismicRichText field={info.texte2} />
                    <img className="AdminPage-image" src={infoImg.im3.url} alt={infoImg.im3.alt} />
                </div>
                <div className='AdminPage-second-title'>
                    <PrismicRichText field={info.texte3}
                        components={{
                            hyperlink: ({ node, children, key }) => (
                                <PrismicLink
                                    field={node.data}
                                    className="link"
                                >
                                    {children}
                                </PrismicLink>
                            )
                        }}
                    />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default HRAdminDashboard;

export const query = graphql`
query HRAdminDashboard {
  prismicHrAdminDashboard (lang: {eq: "en-us"}){
    dataRaw
    data {
      im1 {
        alt
        url
      }
      im2 {
        url
        alt
      }
      im3 {
        alt
        url
      }
      logo {
        alt
        url
      }
    }
  }
}
`